import React, {useEffect, useState} from 'react'

import { reduxForm, Field, reset } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import {Header} from '../common/header'
import Footer from '../common/footer'
import Menu from '../common/menu'
import {Accordion,Button,Modal} from 'react-bootstrap'

import FieldInput, { FieldTextarea } from '../../helpers/inputs.js'

import { getaula } from '../../helpers/actions/content'
import { comentar, upar, deletar, openModal, getquestion, answer } from '../../helpers/actions/content'

import QuizIcone from '../../assets/images/icone_quiz.png'
import UploadIcone from '../../assets/images/icone_upload.png'
import axios from 'axios'

function RenderUpModal(
	upModal, 
	setUpModal, 
	aulaUpload, 
	aulaActive, 
	commUp, 
	setCommUp, 
	arquivo, 
	uploadComments,
	handleUploadComment
){
	const handleCommentChange = e => {
		setCommUp(e.target.value)
	};

	return (
		<>
			<Modal 
				show={upModal}
				size="lg"
				id="modalQuiz"
      			aria-labelledby="contained-modal-title-vcenter"
      			onHide={() => setUpModal(false)}
      			centered>
		    	<Modal.Header closeButton>
		        	<Modal.Title>Comentários sobre o arquivo</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
					<>
					{!!uploadComments && (
						<>
						{uploadComments.map((u,i) => {
							return (
								<div key={i} className={`upload_comment ${(u.is_mine) ? 'm_comment' : 'u_comment'}`}>
									<p className="mb-1"><small>{u.criador} - {u.data}</small></p>
									<p className="mb-0">{u.comentario}</p>
								</div>
							)
						})}
						</>
					)}
					{!!(aulaUpload && aulaActive) && (
						<>
							<textarea className="form-control" onChange={handleCommentChange}></textarea>
							<button type="button" className="mt-2 btn btn-primary" onClick={() => handleUploadComment(arquivo, commUp)}>Enviar</button>
						</>
					)}
					</>
		        </Modal.Body>
		        <Modal.Footer>
		        	<Button variant="secondary" onClick={() => setUpModal(false)}>
		            	Fechar
		          	</Button>
		        </Modal.Footer>
		   	</Modal>
		</>
	)	
}

function RenderModal(props){
	const [choice, setChoice] = useState(0);
	const [buttonShow, setButtonShow] = useState('none')

	const { openModal, answer } = props
	
	let aula = JSON.parse(props.aulaData)

	const handleChoice = e => {
		setChoice(e.target.value)
		setButtonShow('initial')
	}

	const handleNext = () => {
		if(choice == 0 && choice === undefined){
			alert('Você deve selecionar ao menos uma alternativa');
			return;
		}

		setButtonShow('none');
		answer(aula.id, props.questionData.quizz_id, props.questionData.id, choice, props.userToken);
	}
	

	setTimeout(function(){
		window.MathJax.typeset()
	}, 150)

	return (
		<>
			<Modal 
				show={props.modalShow}
				size="lg"
				id="modalQuiz"
      			aria-labelledby="contained-modal-title-vcenter"
      			onHide={() => openModal(false)}
      			centered>
		    	<Modal.Header closeButton>
		        	<Modal.Title>Responder Quiz</Modal.Title>
		        </Modal.Header>
		        <Modal.Body>
		        	<div>
		        	<div dangerouslySetInnerHTML={{ __html:props.errorData }} />
		        	{(props.questionData.length === undefined && props.loadQuestion === false) ? (
		        		<>
		        		<div className="mb-4 text-center clearfix" dangerouslySetInnerHTML={{ __html:props.questionData.name }} />
		        		<div className="mx-xl-4 px-xl-2">
			        		{props.questionData.alternativas.map((alternativa, index) => {
			        			return (
				        			<div key={index} className="custom-control custom-radio py-3">
		                            	<input 
		                            		type="radio" 
		                            		name="alternativa" 
		                            		id={`customRadio${index}`} 
		                            		className="custom-control-input" 
		                            		value={alternativa.id}
		                            		onChange={handleChoice} />
		                                <label className="custom-control-label" htmlFor={`customRadio${index}`}>
		                                	<div dangerouslySetInnerHTML={{ __html:alternativa.text }} />
		                                </label>
		                            </div>
		                        )
			        		})}
			        	</div>
		        		</>
		        	) : (
		        		<>
			        		{(props.loadQuestion) ? (
			        			<>
			        			<div className="loader">Loading...</div>
			        			</>
			        		) : (
			        			<>
			        			</>
			        		)}
			        	</>
		        	)}
		        	</div>
		        </Modal.Body>
		        <Modal.Footer>
		        	<Button variant="secondary" onClick={() => openModal(false)}>
		            	Fechar
		          	</Button>
		          	<Button variant="primary" style={{ display: buttonShow }} onClick={handleNext}>
		            	Próxima
		          	</Button>
		        </Modal.Footer>
		   	</Modal>
		</>
	)	
}

function RenderQuiz(quiz, index, props){
	const { openModal, getquestion } = props

	let aula = JSON.parse(props.aulaData)

	const initQuiz = () => {
		openModal(!props.modalShow)

		if(props.questionData.length == 0 || (quiz.id != props.questionData.quizz_id)){
			getquestion(aula.id, quiz.id, props.userToken)
		}
	}

	return(
		<>
			<Accordion.Toggle as="a" style={{ cursor: "pointer" }} eventKey={index + 1}>
				<h4>
					<div style={{ float: 'left', paddingRight: '20px' }}>
						<img src={QuizIcone} style={{ height: '45px' }} />
					</div>
					{quiz.titulo} <br /><small>Disponível de {quiz.de} à {quiz.ate}</small>
					{(quiz.isActive) ? (
						<span className="float-right"><i className="fas fa-angle-down"></i></span>
					) : (
						<>
						</>
					)}
				</h4>
			</Accordion.Toggle>
			
			{(quiz.isActive) ? (
				<>
				<Accordion.Collapse eventKey={index + 1}>
					<>
						<div dangerouslySetInnerHTML={{ __html:quiz.descricao }} />
						<button className="btn btn-primary btn-xl" onClick={initQuiz}>{quiz.btnText}</button>
					</>
				</Accordion.Collapse>
				</>
			) : (
				<>
				</>
			)}
		</>
	)
}

function openGame(conteudo,aula){
	var obj = {
		aula_id: aula,
		conteudo_id: conteudo.id,
		api_address: localStorage.getItem('api_address'),
		user_token: localStorage.getItem('edutec_user_token'),
		origen: 'pwa'
	}
	const searchParams = new URLSearchParams();
	Object.keys(obj).forEach(key => searchParams.append(key, obj[key]));

	var childwindow = window.open(conteudo.material+'?'+searchParams.toString());

	/*
	var childwindow = window.open(conteudo.material+'?aula_id='+aula+'&conteudo_id='+conteudo.id);

	const msg = {
		api_address: localStorage.getItem('api_address'),
		user_token: localStorage.getItem('edutec_user_token'),
		origin: window.location.protocol + '//' + window.location.host
	}

	setTimeout(function(){
		childwindow.postMessage(msg, conteudo.material);	
	}, 5000)
	*/
	
}

function renderConteudo(conteudo, index, aula){
	return(
		<>
			<Accordion.Toggle as="a" style={{ cursor: "pointer" }} eventKey={index + 1}>
				<h4>
					<div style={{ float: 'left', paddingRight: '20px' }} dangerouslySetInnerHTML={{ __html:conteudo.iconePrint }} />
					{conteudo.name} <br /><small>Disponível à partir de {conteudo.de} à {conteudo.ate}</small>
					{(conteudo.isActive) ? (
						<span className="float-right"><i className="fas fa-angle-down"></i></span>
					) : (
						<>
						</>
					)}
				</h4>
			</Accordion.Toggle>
			
			{(conteudo.isActive) ? (
				<>
				{(conteudo.isGame) ? (
					<>
						<Accordion.Collapse eventKey={index + 1}>
							<a className="btn btn-primary" onClick={() => openGame(conteudo, aula)}>Acessar Jogo</a>
						</Accordion.Collapse>
					</>
				) : (
					<>
						<Accordion.Collapse eventKey={index + 1}>
							<div dangerouslySetInnerHTML={{ __html:conteudo.print }} />
						</Accordion.Collapse>
					</>
				)}
				</>
			) : (
				<>
				</>
			)}
		</>
	)
}

function RenderAula(aulaData, props){
	const [image, setImage] = useState({ raw: "" });
	const [comm, setComm] = useState(null)
	const [upModal, setUpModal] = useState(false)
	const [commUp, setCommUp] = useState(null)
	const [uploadComments, setUploadComments] = useState(null)
	const [sendFile, setSendFile] = useState(null)
	const { handleSubmit, comentar, upar, deletar } = props

	useEffect(() => {
		if(sendFile){
			const config = {
				headers: { 
					Authorization: `Bearer ${props.userToken}`
				}
			}
			axios.get(`${process.env.REACT_APP_URL}upload/${sendFile}/comments`, config)
			.then(resp => {
				if(resp.status == 200){
					setUploadComments(resp.data.comments)
				}
			})
		}
	}, [sendFile])

	let aula = JSON.parse(aulaData)

	const handleChange = e => {
	    if (e.target.files.length) {
	    	setImage({
	        	raw: e.target.files[0]
	      	});
	    }
	};

	const handleCommentChange = e => {
		setComm(e.target.value)
	};

	const handleUpload = () => {
		upar(image, props.userToken, comm, aula.id)
		setComm(null)
		setImage({raw: ""})
	}

	const handleUploadComment = (upload_id, comment) => {
		const config = {
			headers: { 
				Authorization: `Bearer ${props.userToken}`
			}
		}
		let formData = new FormData();
		formData.append('comment', comment);
		axios.post(`${process.env.REACT_APP_URL}upload/${sendFile}/comments`, formData,config)
		.then(resp => {
			if(resp.status == 200){
				axios.get(`${process.env.REACT_APP_URL}upload/${sendFile}/comments`, config)
				.then(resp => {
					if(resp.status == 200){
						setUploadComments(resp.data.comments)
					}
				})
			} else {
				alert('Erro ao fazer o comentário')
			}
		})
	}

	const handleDeleteImage = id => {
		let result = window.confirm("Tem certeza que deseja excluir esse arquivo?")
		if(result){
			deletar(id, props.userToken, aula.id)
		}
	}

	return (
		<>
			<div className="card">
				<div className="card-body">
					<h4 className="mt-0 header-title">Visualizar Trilha de Aprendizagem <small>por: {aula.criador}</small></h4>

	                <p>{ aula.name} - {aula.categoria.name}</p>
	                <hr />

	                <h5>Descrição</h5>
	                <div dangerouslySetInnerHTML={{ __html: aula.descricao}} />
	                <hr />

					{!!aula.bncc && (
						<>
						{aula.bncc.map((b,i) => (
							<>
							<p><strong>{b.bncc}</strong> - {b.nome}</p>
							</>
						))}
						</>
					)}

	                <h5>Turma</h5>
	                <div dangerouslySetInnerHTML={{ __html: aula.materia }} />
	                <hr />

	            </div>
			</div>

			{(aula.materiais.length > 0) ? (
				<>
		        	<Accordion>
		            {(aula.materiais.map((material, index) => {
		            	return (
		                	<div key={index} className="card">
		                		<div className="card-body">
				                	{(material.tipo == 'quiz') ? (
				                		<>
				                		{RenderQuiz(material.content, index, props)}
				                		</>
				                	) : (
				                		<>
				                		{renderConteudo(material.content, index, aula.id)}
				                		</>
				                	)}
			                	</div>
			                </div>
		               	)
		            }))}
		            </Accordion>
		            {RenderModal(props)}
		        </>
	        ) : (
	        	<p>Não há conteúdos para exibir</p>
	        )}
	        <div className="card">
	        	<div className="card-body">
	        		<h4 className="mt-0 header-title">Envie seu arquivo aqui</h4>
	        		<div className="container">
	        			<div className="row">
	        				<div className="col-12 col-xl-8 offset-xl-2">
	        				{(aula.permitir_uploads && aula.active) ? (
	        					<div className="row">
	        						<div className="col-12 col-xl-1 mb-2">
			        					<img src={UploadIcone} style={{ height: '55px' }} />
									</div>
									<div className="col-12 col-xl-11">
			        					<div className="form-group">
			        						<input type="file" style={{ overflow: 'hidden' }} className="form-control" onChange={handleChange} accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*, application/pdf, video/*, audio/*, zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed,video/mp4,.Apk,.APK,.apk,.Jar,.jar,.JAR,.aia,.Aia,.AIA,.java,.Java,.JAVA" />
											<p className="mt-4 mb-2">Descreva alguma observação sobre o arquivo que está enviando</p>
											<textarea className="form-control" onChange={handleCommentChange}></textarea>
											<p className="mt-1 mb-4">
			        							<i>Você deverá fazer o envio de arquivos somente entre os dias {aula.inicio} e {aula.fim}</i>
			        						</p>
			        					</div>
										<button type="button" className="btn btn-primary" onClick={handleUpload}><span dangerouslySetInnerHTML={{ __html: props.sendText }} /></button>
			        				</div>
	        					</div>
	        				) : (
	        					<p className="text-center">Envio de arquivos não permitidos no momento</p>
	        				)}
	        				</div>
	        			</div>
	        		</div>
	        	</div>
	        </div>

	        <form id="opengame" action="" method="post" target="_blank">
				<input type="hidden" name="aula_id" />
				<input type="hidden" name="game_id" />
				<input type="hidden" name="user_token" />
				<input type="hidden" name="api_url" />
	        </form>

	        {(props.uploadsData.length > 0) ? (
	        	<>
	        	{(props.loadMedia) ? (
	        		<>
	        		<div className="loader">Loading...</div>
	        		</>
	        	) : (
					<>
						<div className="row mb-4 pb-4">
							<h4 className="mt-2 header-title col-12">Arquivos enviados por mim</h4>
							{(props.uploadsData.map((file, index) => {
								return(
									<div key={index} className="col-12 col-md-6 col-xl-4">
										<div className="card profile-card">
											<div className="card-body p-0">
												<div className="media p-3  align-items-center">
													<a className="nav-link file-category-folder bg-soft-primary rounded-circle thumb-lg text-center">
														<i className={file.icon} style={{ lineHeight: 'calc(60px - 1rem)' }}></i>
													</a>
													<div className="media-body ml-3  align-self-center">
														{file.filename}
														<br />
														<small><i>{file.criado}</i></small>
													</div>
													<div style={{ maxWidth: '71px' }} className="text-right">
														<a className="btn btn-success btn-sm" href={file.filelink} target="_blank">
															<i className="fas fa-angle-right mr-2"></i> Abrir
														</a>
														<button type="button" className="mt-1 bnt btn-info btn-sm" onClick={() => {{setSendFile(file.id); setUpModal(true)}}}>
														<i className="far fa-comment mr-2"></i> Msg
														</button>
														{(aula.permitir_uploads && aula.active) ? (
															<>
															<div className="mt-1 text-right">
																<button className="btn btn-danger btn-sm" onClick={() => {handleDeleteImage(file.id)}}>
																	<i className="fas fa-trash"></i>
																</button>
															</div>
															</>
														) : (
															<>
															</>
														)}
													</div>
												</div>
											</div>
										</div>
									</div>
								)
							}))}
						</div>
						{RenderUpModal(
							upModal, 
							setUpModal, 
							aula.permitir_uploads, 
							aula.active, 
							commUp, 
							setCommUp, 
							sendFile, 
							uploadComments,
							handleUploadComment
						)}
					</>
		        )}
		        </>
	        ) : (
	        	<>
	        	</>
	        )}


	        <div className="card">
	        	<div className="card-body">
	        		<h4 className="mt-0 header-title">Comentários</h4>	
	        		<div className="container">
	        			<div className="row">
	        				<div className="col-12 col-xl-8 offset-xl-2">
						        {(aula.permitir_comentarios && aula.active) ? (
						        	<>
						        	<form onSubmit={handleSubmit(value => comentar(value, props.userToken, aula.id))}>
								    	<div className="form-group">
								        	<label htmlFor="username">Faça o seu comentário</label>
								            <div className="form-group">
								            	<Field
													name="comentario0"
													placeholder="Escreva seu comentário"
													component={ FieldTextarea }
												/>
								            </div>                      
								        </div>

								        <div className="form-group mb-0 row">
								        	<div className="col-12 mt-2">
								            	<button className="btn btn-primary text-right waves-effect waves-light" type="submit">Publicar Comentário</button>
								            </div>
								        </div>  
								    </form>

								    <hr />
								    </>
						        ) : (
						        	<>
						        	</>
						        )}

						        <div id="comentarios">
									{renderComentarios(aulaData, props)}
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
	    </>
	)
}

function renderComentarios(aulaData, props){
	let aula = JSON.parse(aulaData)

	if(props.loadingData){
		return(
			<>
		    <div className="loader">Loading...</div>
		    </>
		)
	} else if (props.commentsData.length > 0) { 
		const { handleSubmit, comentar } = props

		return(
			<>
			{(props.commentsData).map((comment, index) => {
				return(
					<div key={index} className="mb-4">
						<div className="media">
                        	
                            <img src={comment.user.avatarFiltered} alt="user" className="rounded-circle thumb-md" />
                            
                            <div className="media-body ml-3 align-self-center">
                            	<p className="font-14 mb-0">{comment.user.role.display_name} - {comment.user.name}</p>
                                <p className="mb-0 font-12 text-muted">Em {comment.created_at}</p>
                            </div>
                        </div>
                        <p className="mt-4 mb-2" style={{ fontSize: '16px' }}>{comment.comentario}</p>
                        <div className="replies pl-4 ml-3">
                       	{(comment.replies).map((reply, ind) => {
                       		return(
	                       		<div key={ind} className="mb-4">
	                       			<hr />
	                       			<div className="media">
                        	
			                            <img src={reply.user.avatarFiltered} alt="user" className="rounded-circle thumb-md" />
			                            
			                            <div className="media-body ml-3 align-self-center">
			                            	<p className="font-14 mb-0">{reply.user.role.display_name} - {reply.user.name}</p>
			                                <p className="mb-0 font-12 text-muted">Em {reply.created_at}</p>
			                            </div>
			                        </div>
			                        <p className="mt-3 mb-2" style={{ fontSize: '16px' }}>{reply.comentario}</p>
	                       		</div>
	                       	)
                       	})}
                        </div>
                        {(aula.permitir_comentarios && aula.active) ? (
                        	<>
		                        <form onSubmit={handleSubmit(value => comentar(value, props.userToken, aula.id, comment.id, index + 1))}>
					    			<div className="form-group">
					                	<label htmlFor="username" style={{ fontSize: '12px' }}>Responda a esse comentário</label>
					                    <div className="form-group">
					                    	<Field
												name={`comentario${index + 1}`}
												placeholder="Escreva seu comentário"
												component={ FieldInput }
											/>
					                    </div>                      
					                </div>

					                <div className="form-group mb-0 row">
					                	<div className="col-12 mt-2">
					                    	<button className="btn btn-primary btn-xs text-right waves-effect waves-light" type="submit">Responder</button>
					                    </div>
					                </div>  
					            </form>
								<hr />
							</>
						) : (
							<>
							</>
						)}
					</div>
				)
			})}
			</>
		)
	} else {
		return(
			<>
			<p className="text-center">Ainda não há comentários realizados.</p>
			</>
		)
	}
}

function Aula(props){
	const breadcrumb = [props.clienteData.nome, 'Trilhas de Aprendizagem', 'Visualizar Aula']

	const {getaula} = props
	const aula = props.match.params['id']
	setTimeout(function(){
		if(props.aulaData.length == 0){
			getaula(props.userToken, aula)
		}
	}, 1500)

	return(
		<div>
			<Header titulo="Trilhas de Aprendizagem" icone="fas fa-book" breadcrumb={breadcrumb} />

			<div className="page-wrapper">
	            <div className="page-wrapper-inner">
	                <Menu />
	            </div>
	            <div className="page-content">
	                <div className="container-fluid">
	                    <div className="row">
	                    	<div className="col-12">
	                    		{(props.aulaData.length > 0) ? (
	                    			<>
	                    			{RenderAula(props.aulaData, props)}
	                    			</>
	                    		) : (
	                    			<>
	                    			<div className="loader">Loading...</div>
	                    			</>
	                    		)}
	                    	</div>
	                    </div>
	                </div>

	                <Footer cliente={props.clienteData.nome} sistema={props.clienteData.sistema} />
	            </div>
	        </div>
		</div>
	)
}

Aula = reduxForm({form: 'commentForm'})(Aula)
const mapStateToProps = (state, ownProps) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	aulaData: state.content.aula,
	commentsData: state.content.comments,
	uploadsData: state.content.uploads,
	loadingData: state.main.loading,
	modalShow: state.content.modalShow,
	questionData: state.content.question,
	loadQuestion: state.content.loadquestion,
	errorData: state.profile.errorUser,
	sendText: state.content.sendtext,
	loadMedia: state.content.loadmedia
})

const mapDispatchToProps = dispatch => bindActionCreators({
	getaula,
	comentar,
	upar,
	deletar,
	openModal,
	getquestion,
	answer
}, dispatch)

const conAula = connect(mapStateToProps, mapDispatchToProps)(Aula)
export default conAula