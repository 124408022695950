import React, {useState, useEffect, useRef} from 'react'

import { reduxForm, Field, reset } from 'redux-form'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { getmeeting } from '../../helpers/actions/meetings'

var CryptoJS = require("crypto-js");
const Webex = require(`webex`);
const jwt = require('jsonwebtoken');

var webex = Webex.init({
	meetings: {
		reconnection: {
			enabled: true
		},
		enabledRtx: true
	}
});

var activeMeeting;

// Disable screenshare on join in Safari patch
const isSafari = /Version\/[\d.]+.*Safari/.test(navigator.userAgent);
const isiOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

var meetingStreamsLocalVideo = document.querySelector('#local-video');
var meetingStreamsRemotelVideo = document.querySelector('#remote-video');
var meetingStreamsRemoteAudio = document.querySelector('#remote-audio');
var meetingStreamsLocalShare = document.querySelector('#local-screenshare');
var meetingStreamsRemoteShare = document.querySelector('#remote-screenshare');

var toggleSourcesMediaDirection = document.querySelectorAll('[name=ts-media-direction]');
var toggleSourcesSendAudioStatus = document.querySelector('#ts-toggle-audio-status');
var toggleSourcesSendVideoStatus = document.querySelector('#ts-toggle-video-status');
var toggleSourcesSendShareStatus = document.querySelector('#ts-screenshare-status');

var toggleSourcesQualityStatus = document.querySelector('#ts-sending-quality-status');
var toggleSourcesMeetingLevel = document.querySelector('#ts-sending-qualities-list');

const sourceDevicesGetMedia = document.querySelector('#sd-get-media-devices');
const sourceDeviceControls = document.querySelector('#source-devices-controls');
const receivingSourcesControls = document.querySelector('#receiving-sources-controls');
const audioInputDeviceStatus = document.querySelector('#sd-audio-input-device-status');
const audioOutputDeviceStatus = document.querySelector('#sd-audio-output-device-status');
const videoInputDeviceStatus = document.querySelector('#sd-video-input-device-status');

let currentMediaStreams = [];

function getMediaSettings() {
  const settings = {};

  settings['receiveAudio'] = true;
  settings['receiveVideo'] = true;
  settings['receiveShare'] = true;
  settings['sendAudio'] = true;
  settings['sendVideo'] = true;
  settings['sendShare'] = false;

  if (isSafari || isiOS) {
	  // It's been observed that trying to setup a Screenshare at join along with the regular A/V streams
	  // causes Safari to loose track of it's user gesture event due to getUserMedia & getDisplayMedia being called at the same time (through our internal setup)
	  // It is recommended to join a meeting with A/V streams first and then call `meeting.shareScreen()` after joining the meeting successfully (on all browsers)
	  settings['sendShare'] = false;
	  console.warn('MeetingControsl#getMediaSettings() :: Please call `meeting.shareScreen()` after joining the meeting');
	}

  return settings;
}

const htmlMediaElements = [
  meetingStreamsLocalVideo,
  meetingStreamsLocalShare,
  meetingStreamsRemotelVideo,
  meetingStreamsRemoteShare,
  meetingStreamsRemoteAudio
];

function CallingV(props){
	const { getmeeting } = props
	const [isReady, setIsReady] = useState(false)
	const [isCalling, setIsCalling] = useState(false)
	const localVideo = useRef(null)
	const remoteVideo = useRef(null)
	const remoteAudio  = useRef(null)
	const sourceDevicesAudioInput = useRef(null)
	const sourceDevicesAudioOutput = useRef(null)
	const sourceDevicesVideoInput = useRef(null)

	useEffect(() => {
		if(props.meetingData.length === undefined){
			const sup = props.meetingData.meeting_sup

			webex.meetings.register()
		    .then(() => {
		    	console.log(sup)
		    	webex.meetings.create(props.meetingData.meeting_sup)
		    	.then((meeting) => {
		    		console.log(meeting)
		    		activeMeeting = meeting;
		    		setIsReady(true)
		    		getMediaStreams()
		    		clearMediaDeviceList()
		    		getMediaDevices()
		    	})
		    	.catch((error) => {
		    		console.warn('Create#register() :: error registering', error);		
		    	})
		    })
		}
	}, [props.meetingData])

	useEffect(() => {
		var token = jwt.sign(
		  payload,
		  Buffer.from('Eq4R6SV4LPCQqJtv9I0tGNbxfde36ReFS9qAZnGrF8U=', 'base64'),
		  { expiresIn: '3h' }
		);

		webex.once(`ready`, function() {
			console.log('estou pronto')
		    webex.authorization.requestAccessTokenFromJwt({jwt: token})
		   	.then(() => {
		   		console.log('registrei')
		    	// wait until the SDK is loaded and ready
					webex.meetings.register()
			    .then(() => {
			    	getmeeting(props.userToken, props.id)
			    })
			    .catch((error) => {
			      console.warn('Authentication#register() :: error registering', error);
			    })	 
		    })
		});
		// wait until the SDK is loaded and ready
	}, [])

	function clearMediaDeviceList() {
	  sourceDevicesAudioInput.current.innerText = '';
	  sourceDevicesAudioOutput.current.innerText = '';
	  sourceDevicesVideoInput.current.innerText = '';
	}

	function getMediaDevices() {
	  const meeting = activeMeeting;

	  if (meeting) {
	    console.log('MeetingControls#getMediaDevices()');
	    meeting.getDevices()
	      .then((devices) => {
	      	console.log(devices)
	        devices.forEach((device) => {
	          populateSourceDevices(device);
	        });
	      });
	  }
	  else {
	    console.log('MeetingControls#getMediaDevices() :: no valid meeting object!');
	  }
	}

	function populateSourceDevices(mediaDevice) {
	  let select = null;
	  const option = document.createElement('option');

	  // eslint-disable-next-line default-case
	  switch (mediaDevice.kind) {
	    case 'audioinput':
	      select = sourceDevicesAudioInput.current;
	      break;
	    case 'audiooutput':
	      select = sourceDevicesAudioOutput.current;
	      break;
	    case 'videoinput':
	      select = sourceDevicesVideoInput.current;
	      break;
	  }
	  option.value = mediaDevice.deviceId;
	  option.text = mediaDevice.label;
	  select.appendChild(option);
	}

	function getMediaStreams(mediaSettings = getMediaSettings(), audioVideoInputDevices = {}) {
	  const meeting = activeMeeting;

	  console.log('MeetingControls#getMediaStreams()');

	  if (!meeting) {
	    console.log('MeetingControls#getMediaStreams() :: no valid meeting object!');

	    return Promise.reject(new Error('No valid meeting object.'));
	  }

	  // Get local media streams
	  return meeting.getMediaStreams(mediaSettings, audioVideoInputDevices)
	    .then(([localStream, localShare]) => {
	      console.log('MeetingControls#getMediaStreams() :: Successfully got following streams', localStream, localShare);
	      // Keep track of current stream in order to addMedia later.
	      const [currLocalStream, currLocalShare] = currentMediaStreams;

	      /*
	       * In the event of updating only a particular stream, other streams return as undefined.
	       * We default back to previous stream in this case.
	       */
	      currentMediaStreams = [localStream || currLocalStream, localShare || currLocalShare];

	      return currentMediaStreams;
	    })
	    .then(([localStream]) => {
	      if (localStream && mediaSettings.sendVideo) {
	      	localVideo.current.srcObject = localStream;
			localVideo.current.play();
			const mediaSream = localVideo.current.captureStream();
	        //localVideo.current.srcObject = localStream;
	      }

	      return {localStream};
	    })
	    .catch((error) => {
	      console.log('MeetingControls#getMediaStreams() :: Error getting streams!');
	      console.error();

	      return Promise.reject(error);
	    });
	}

	function addMedia() {
	  console.log('To aqui')
	  const meeting = activeMeeting;
	  const [localStream, localShare] = currentMediaStreams;

	  console.log('MeetingStreams#addMedia()');

	  if (!meeting) {
	    console.log('MeetingStreams#addMedia() :: no valid meeting object!');
	  }

	  meeting.addMedia({
	    localShare,
	    localStream,
	    mediaSettings: getMediaSettings()
	  }).then(() => {
	    console.log('MeetingStreams#addMedia() :: successfully added media!');
	  }).catch((error) => {
	    console.log('MeetingStreams#addMedia() :: Error adding media!');
	    console.error(error);
	  });

	  // Wait for media in order to show video/share
	  meeting.on('media:ready', (media) => {
	  	console.log(media)
	    // eslint-disable-next-line default-case
	    switch (media.type) {
	      case 'remoteVideo':
	        remoteVideo.current.srcObject = media.stream;
	        remoteVideo.current.play()
	        const mediaSreamRm = remoteVideo.current.captureStream();
	        break;
	      case 'remoteAudio':
	        remoteAudio.current.srcObject = media.stream;
	        remoteAudio.current.play()
	        const mediaSreamAu = remoteAudio.current.captureStream();
	        break;
	      case 'remoteShare':
	        //remoteShare.current.srcObject = media.stream;
	        break;
	      case 'localShare':
	        //meetingStreamsLocalShare.srcObject = media.stream;
	        break;
	    }
	  });
	}


	function toggleDisplay(elementId, status) {
	  const element = document.getElementById(elementId);

	  if (status) {
	    element.style.visibility = 'visible';
	  }
	  else {
	    element.style.visibility = 'hidden';
	  }
	}

	webex.meetings.on('meeting:added', (m) => {
		console.log(m)
	    const {type} = m;

	    if (type === 'INCOMING') {
	      const newMeeting = m.meeting;

	      toggleDisplay('incomingsection', true);
	      newMeeting.acknowledge(type);
	    }
	 });

	function join(meetingId){
		const meeting = activeMeeting
		const resourceId = webex.devicemanager._pairedDevice ?
		    webex.devicemanager._pairedDevice.identity.id :
		    undefined;

		meeting.join({
			pin: '',
			moderator: false,
			moveToResource: false,
			resourceId
		})
		.then(() => {
			console.log('oi 1')
			setIsCalling(true)
			addMedia()
		})
		.catch((error) => {
			if (error.joinIntentRequired) {
				meeting.join(({moderator: false})).then(() => {
			    	console.log('oi 2')
			    });
			} else {
				console.log('oi 3')
			}
		});
	}

	var dateObj = new Date();
	var month = dateObj.getUTCMonth() + 1; //months from 1-12
	var day = dateObj.getUTCDate();
	var year = dateObj.getUTCFullYear();

	var payload = {
	  "sub": "guest-user-"+props.userData.id+'-'+year+month+day,
	  "name": props.userData.name,
	  "iss": "Y2lzY29zcGFyazovL3VybjpURUFNOnVzLXdlc3QtMl9yL09SR0FOSVpBVElPTi80MDA1MGZiZC01MzYwLTRmZjEtODc1Yy0zNzc0OWExOWZhOGU",
	};

	return(
		<>
		{props.meetingData.length === undefined ? (
			<div className="card">
				<div className="card-body">
		        	{!!isReady &&
		        	<div className="row">
		        		<div className="col-12 text-center">
		        			<div className="py-2">
		        				<h4>{ props.meetingData.titulo }</h4>
		        				<p><strong>Horário de Início:</strong> {props.meetingData.inicio}</p>
		        				<p><strong>Criada por:</strong> {props.meetingData.author}</p>
		        				<div className="row">
		        					<div className={`col-12 ${(isCalling) ? 'col-md-6' : 'col-md-9'}`}>
		        						<video id="local-video" muted="true" autoPlay playsInline ref={localVideo}></video>
		        					</div>
		        					{!!isCalling &&
		        					<div className="col-12 col-md-6">
		        						<video id="remote-video" autoPlay playsInline ref={remoteVideo}></video>
		        						<audio id="remote-audio" autoPlay ref={remoteAudio}></audio>
		        					</div>	
		        					}
		        					{!isCalling &&
		        					<div className="col-12 col-md-3">
		        						<fieldset>
								        	<div className="mb-2">
									            <select ref={sourceDevicesAudioInput} className="form-control" id="sd-audio-input-devices" placeholder="Entrada de Áudio"></select>
								          	</div>
								          	<div className="mb-2">
									            <select ref={sourceDevicesAudioOutput}className="form-control" id="sd-audio-output-devices" placeholder="Saída de Áudio"></select>
								          	</div>
								          	<div className="mb-2">
									            <select ref={sourceDevicesVideoInput} className="form-control" id="sd-video-input-devices" placeholder="Entrada de Vídeo"></select>
								          	</div>
								          	<div>
								                <label>
								                  <input type="checkbox" name="ts-media-direction" value="receiveAudio" checked />receiveAudio
								                </label>
								                <label>
								                  <input type="checkbox" name="ts-media-direction" value="receiveVideo" checked />receiveVideo
								                </label>
								                <label>
								                  <input type="checkbox" name="ts-media-direction" value="receiveShare" checked />receiveShare
								                </label>
								                <label>
								                  <input type="checkbox" name="ts-media-direction" value="sendAudio" checked />sendAudio
								                </label>
								                <label>
								                  <input type="checkbox" name="ts-media-direction" value="sendVideo" checked />sendVideo
								                </label>
								                <label>
								                  <input type="checkbox" name="ts-media-direction" value="sendShare" id="sendShareToggle" />sendShare
								                </label>
								            </div>
								        </fieldset>
		        						<div>
				        					<button onClick={() => join()} type="button" className="btn btn-primary btn-block">Participar</button>
				        				</div>
		        					</div>
		        					}
		        				</div>
		        			</div>
		        		</div>
		        	</div>
		        	}
		        	{!isReady &&
		    		<div className="row">
						<div className="col-6 offset-md-3">
							<div className="loader">Loading...</div>
						</div>
					</div>    		
		        	}
		        </div>
		    </div>
		) : (
			<div className="row">
				<div className="col-6 offset-md-3">
					<div className="loader">Loading...</div>
				</div>
			</div>
		)}
		</>
	)
}

const mapStateToProps = (state, ownProps) => ({
	userData: state.profile.userData,
	userToken: state.profile.token,
	clienteData: state.cliente.cliente,
	meetingData: state.meetings.meeting
})

const mapDispatchToProps = dispatch => bindActionCreators({
	getmeeting
}, dispatch)
const conView = connect(mapStateToProps, mapDispatchToProps)(CallingV)
export default conView